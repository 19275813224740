import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import * as URLS from 'src/config/api/Urls'

import { Bg, CloseButton, Container, Section } from './style'
import { FormData } from './_FormData'
import { UseFormMethods, useForm } from 'react-hook-form'

import SuccessImage from '../../assets/images/success.png'
import ErroImage from '../../assets/images/erro.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export type FormProps = {
  cpf: string;
  email: string;
  nome: string;
  accept: boolean;
};

export const Dicas = () => {
  const methods: UseFormMethods<FormProps> = useForm<FormProps>()
  const [ status, setStatus ] = useState<boolean | undefined>()
  const [ erro, setErro ] = useState<boolean | undefined>()
  const [ accept, setAccept ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const onSubmit = async (data: FormProps) => {
    const formData = [
      {
        campanha: 'Noticias Conta Empresarial',
        cpfCnpj: data.cpf.replace(/\D/g, ''),
        nome: data.nome,
        email: data.email,
        aceite: accept,
      },
    ]

    try {
      await axios.post(
        `${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      setStatus(true)
      sendDatalayerEvent({
        section: 'dobra_10',
        element_action: 'click button',
        section_name: 'Fique por dentro de tudo',
        element_name: 'Cadastrar',
        step: 'success',
      })
      methods.reset()
      setAccept(false)
    } catch (e) {
      setErro(true)
      methods.reset()
    }
  }

  const closeButton = (
    <CloseButton
      className='border-0 bg-transparent pt-4'
      onClick={() => setStatus(undefined)}
    >
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  const HandleScroll = (id: string) => {
    const section = document.getElementById(id)
    const position = section?.offsetTop || 0
    const poss = position - 187 // Está descontando a altura do header
    window.scroll({
      top: poss,
      behavior: 'smooth',
    })
  }

  const clearLocation = (location: string) => {
    const url = new URL(location)
    const id = url.searchParams.get('id')
    return id
  }

  useEffect(() => {
    const location = clearLocation(window.location.href)

    if (location) {
      setTimeout(() => HandleScroll(location), 100)
    }
  }, [])

  return (
    <>
      {(status || erro) && (
        <>
          <Bg />
          <Container
            isModal={() => {
              setStatus(undefined)
              setErro(false)
            }}
            className='d-flex align-items-center sent'
          >
            {closeButton}
            <div className='col-12 px-0 px-md-5 d-flex flex-column justify-content-between h-100'>
              <div />
              <div>
                <div className='col-12 mb-4 mx-auto text-center'>
                  {status && <img src={SuccessImage} alt='Sucesso' />}
                  {erro && <img src={ErroImage} alt='Erro' />}
                </div>
                <p className='fs-14 lh-17 fs-md-20 lh-md-25 text-grayscale--400 text-center text-md-left fw-600 mr-md-5'>
                  {erro &&
                    'Houve um erro por aqui, por isso não foi possível continuar. Tente novamente mais tarde.'}
                  {status &&
                    'Prontinho! Recebemos os seus dados e enviaremos para o seu e-mail nossas melhores soluções.'}
                </p>
              </div>
              <button
                className='btn btn-orange--extra text-white fw-600 text-none fs-14'
                onClick={() => {
                  setStatus(undefined)
                  setErro(false)
                }}
              >
                Entendi
              </button>
            </div>
          </Container>
        </>
      )}
      <Section id='formulario'>
        <div className='container'>
          <div className='row justify-items-md-center align-items-md-center'>
            <div className='col-12 col-md-6'>
              <h2 className='font-sora fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 text-orange--extra fw-600 mb-4'>
                Fique por dentro <span className='d-lg-block'>de tudo</span>
              </h2>
              <p className='fs-14 lh-17 text-grayscale--500'>
                Quer ficar por dentro das soluções do Inter e receber<span className='d-lg-block'> dicas mensalmente para simplificar a gestão financeira</span> do seu negócio?

              </p>
              <p className='fs-14 lh-17 text-grayscale--500'>Cadastre-se gratuitamente e aproveite!</p>
            </div>
            <div className='col-12 col-md-6 mt-3 d-md-flex flex-md-column justify-content-md-center align-items-lg-center'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormData
                  methods={methods}
                  accept={accept}
                  setAccept={setAccept}
                />
                <div className='mx-auto d-flex align-items-center justify-content-center'>
                  <button
                    className='btn btn-orange--extra text-none mx-md-auto mw-100'
                    disabled={!accept}
                  >
                    Cadastrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}
