import React, { ChangeEvent, useState } from 'react'
import { UseFormMethods } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'

import { Checkbox, FormDiv } from './style'
import { FormProps } from './_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type FormDataProps = {
  methods: UseFormMethods<FormProps>;
  accept: boolean;
  setAccept: (accept: boolean) => void;
};

export const FormData = ({ methods, accept, setAccept }: FormDataProps) => {
  const { errors, register, setValue }: UseFormMethods<FormProps> = methods
  const [ email, setEmail ] = useState('')
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <>
      <div className='w-100'>
        <FormDiv className='col-12 pb-2 d-flex flex-column px-0'>
          <label htmlFor='nome' className='fs-12 lh-15 fw-600 text-grayscale--500'>Nome</label>
          <input
            ref={register({
              required: 'Nome obrigatório',
              validate: {
                isName: (value: string) => Validations.name(value) || 'Insira um nome com mais de 5 caracteres',
              },
            })}
            name='nome'
            id='nome'
            type='text'
            maxLength={80}
            placeholder='Digite seu nome'
          />
          { errors.nome && <p className='fs-12 mt-1 mb-0 text-orange--extra'>{errors.nome.message}</p> }
        </FormDiv>

        <FormDiv className='col-12 pb-2 d-flex flex-column px-0'>
          <label htmlFor='cpf' className='fs-12 lh-15 fw-600 text-grayscale--500'>CPF</label>
          <input
            ref={register({
              required: 'Digite um CPF válido',
              validate: {
                isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
              },
            })}
            name='cpf'
            id='cpf'
            type='tel'
            placeholder='Digite seu CPF'
            onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
          />
          {errors.cpf && <p className='fs-12 mt-1 mb-0 text-orange--extra'>{errors.cpf.message}</p>}
        </FormDiv>

        <div className='col-12 px-0 mb-2'>
          <FormDiv className='col-12 pb-2 d-flex flex-column mt-2 px-0'>
            <label htmlFor='email' className='fs-12 lh-15 fw-600 text-grayscel--500'>E-mail</label>
            <input
              ref={register({
                required: 'Informe um e-mail válido',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'E-mail inválido',
                },
              })}
              name='email'
              id='email'
              value={email}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
              maxLength={80}
              type='text'
              placeholder='Digite seu e-mail'
            />
            { errors.email && <p className='fs-12 mt-1 mb-0 text-orange--extra'>{errors.email.message}</p> }
          </FormDiv>
        </div>
      </div>

      <div className='mx-auto'>
        <Checkbox onClick={() => setAccept(!accept)}>
          <input
            type='checkbox'
            className='form-input-check'
            checked={accept}
          />
          <label className='form-label-check text-grayscale--500 fs-12 lh-15 pt-lg-1'>
            Autorizo o Inter a tratar meus dados pessoais para envio de
            comunicações sobre seus produtos e serviços e também estou de acordo
            com a{' '}
            <a
              className='fw-700'
              title='Acessar Política de Privacidade do Inter'
              href='https://inter.co/politica-de-privacidade/privacidade/'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_10',
                  element_action: 'click button',
                  section_name: 'Fique por dentro de tudo',
                  element_name: 'Política de Privacidade',
                  redirect_url: 'https://inter.co/politica-de-privacidade/privacidade/',
                })
              }}
            >
              Política de Privacidade.
            </a>
          </label>
        </Checkbox>
      </div>
    </>
  )
}
