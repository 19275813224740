import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green, white, orange } from 'src/styles/colors'

export const TableSection = styled.section`
  h2 {
    color: ${orange.extra};
  }
`

export const SolutionsTable = styled.div`
  box-shadow: 0 1px 4px #FDF8EE;

  @media ${device.tablet} {
    box-shadow: none;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  .lineColorHeader {
    position: sticky;
    top: 180px;
    background-color: ${white};
    box-shadow: 0 1px 4px #FDF8EE;
    z-index: 1;
    border-radius: 10px 10px 0 0;
  }

  .lineColor {
    display: flex;
    justify-content: space-between;

    @media ${device.tablet} {
      :nth-child(even) {
        background-color: #FDF8EE !important;
      }
    }
  }
`

export const TableCell = styled.div`
  p {
    font-family: 'Sora';

    a {
      color: ${orange.extra};
      text-decoration: underline;
      font-family: 'Inter';
    }
  }

  .tableTop {
    background-color: #FDF8EE;

    @media ${device.tablet} {
      background-color: transparent;
    }
  }

  &:nth-child(2) {
    @media ${device.tablet} {
      border-radius: 10px 10px 0 0;
    }
  }

  &:last-child {
    @media ${device.tablet} {
      border-radius: 0 0 10px 10px;
    }
  }

  @media ${device.tablet} {
    height: 80px;
  }

  .borderRight {
    border-right: 1px solid #F5F6FA;

    @media ${device.tablet} {
      border-right: none;
    }
  }

  div {
    width: 81px;

    &:first-child {
      width: 145px;
    }

    @media ${device.tablet} {
      width: 134px;

      &:first-child {
        width: 366px;
      }
    }

    @media ${device.desktopLG} {
      width: 157px;

      &:first-child {
        width: 474px;
      }
    }

    @media ${device.desktopXL} {
      width: 187px;

      &:first-child {
        width: 612px;
      }
    }

    @media ${device.desktopXXXL} {
      width: 171px;
    }

    img {
      width: 24px;

      @media ${device.tablet} {
        width: 38px;
      }
    }
  }
`
