import { white, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Button = styled.a`
  background: ${orange.extra};
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;


  &:focus, &:hover {
    outline: none;
    color: ${white};
  }
`
