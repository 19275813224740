import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Button } from './style'

const MaquininhaInterPag = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-md-center justify-content-lg-between'>
          <div className='col-12 col-md-8 col-lg-5'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pj-interpag-maquininha/image.webp'
              altDescription='Super app Inter Empresas com maquininha no celular ao lado de uma maquininha da Inter Pag.'
              arrayNumbers={[ ]}
              arrayNumbersHeight={[ ]}
            />
          </div>
          <div className='col-12 col-lg-6'>
            <h2 className='fs-24 lh-28 fs-md-40 lh-md-44 text-orange--extra fw-400 mb-5 text-center text-lg-left'>
              Maquininha de cartão é <strong>Inter Pag</strong>
            </h2>
            <p className='fs-18 lh-21 text-grayscale--500 fw-400 mb-4 text-center text-lg-left'>
              Receba suas vendas de crédito em até 1 dia útil, aumente seus lucros aproveitando as melhores taxas do mercado e garanta mais agilidade nas vendas transformando seu celular em maquininha.
            </p>
            <Button
              href='https://inter.co/empresas/maquininha-de-cartao/'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  section_name: 'Maquininha de cartão é Inter Pag',
                  element_name: 'Aproveitar agora',
                })
              }}
            >
              Aproveitar agora
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MaquininhaInterPag
