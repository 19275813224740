import React, { useState } from 'react'
import BreadCrumb from 'src/components/Breadcrumb/index'
import { useForm } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import useUtms from 'src/hooks/useUtms'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/styles/breakpoints'
import ScrollTo from 'src/components/ScrollTo'
import AcceptTerms from 'src/components/AcceptTerms/ContaDigitalPJ'

import { Section, Card, ScrollLink, Button, ButtonReverse, ButtonDisable } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

type FormProps = {
  nome: string;
  cnpj: string;
}

const ContaPjDigital = () => {
  const width = useWidth(300)
  const [ accept, setAccept ] = useState(false)
  // eslint-disable-next-line @typescript-eslint/typedef
  const { register, errors, setValue, getValues, watch } = useForm<FormProps>()
  const cnpjWithOutMaks = watch('cnpj') ? watch('cnpj').replace(/\D/g, '') : undefined
  const utmsLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}/?company=${watch('nome')}&cnpj=${cnpjWithOutMaks}`, campanha: '' })
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='hero-conta-pj' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 col-md-6'>
            <div className='d-none d-lg-block'>
              <BreadCrumb
                sectionName='Conta Digital PJ pro seu negócio superar qualquer desafio'
                type='Empresas'
                text='Conta Digital PJ'
                link='/empresas/conta-digital/pessoa-juridica/'
                styles='bread-crumb'
              />
            </div>
            <h1 className='fs-36 lh-43 fs-md-40 lh-md-44 fs-xl-56 lh-xl-60 text-md-white text-orange--extra mb-4 fw-600'>
              <span className='d-lg-block'>Conta PJ Digital: destrave novas possibilidades</span> pro seu negócio
            </h1>
            <p className='fs-18 lh-21 fs-md-14 lh-md-16 fs-lg-18 lh-lg-21  mb-4 fw-400'>
              Super App Financeiro Inter Empresas: Mais que uma Conta empresarial gratuita, um jeito inteligente de cuidar das finanças do seu negócio.
            </p>

          </div>
          <div className='col-12 col-md-6'>
            <Card>
              <p className='fs-16 lh-19 fs-md-20 lh-md-24 text-md-center text-grayscale--500 mb-3 fw-700'>
                <span className='d-xl-block'>Faça tudo que sua empresa precisa,</span>
                sem nenhuma tarifa! Comece agora.
              </p>
              <form>
                <label className='fs-14 lh-16 fw-400 text-grayscale--400'>Nome empresarial</label>
                <input
                  name='nome'
                  placeholder='Digite o nome da empresa'
                  type='text'
                  className='form-input'
                  ref={register({
                    required: 'Nome obrigatório',
                    validate: {
                      isName: (value: string) => Validations.name(value) || 'Coloque o nome completo',
                    },
                  })}
                />
                {errors.nome && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.nome.message}</p>}
                <label className='fs-14 lh-16 fw-400 mb-0 text-grayscale--400 mt-3'>CNPJ</label>
                <input
                  name='cnpj'
                  placeholder='Digite o CNPJ da empresa'
                  type='text'
                  className='form-input'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue('cnpj', event.target.value)}
                  value={getValues('cnpj') ? Masks.MaskCNPJ(getValues('cnpj')) : undefined}
                  ref={register({
                     required: 'CNPJ obrigatório',
                     validate: {
                       isCpf: (value: string) => Validations.cpf(value) || 'Insira um CPF válido',
                     },
                   })}
                />
                {errors.cnpj && <p className='fs-12 lh-15 mb-0 pt-1 text-red--base text-right'>{errors.cnpj.message}</p>}
                <div className='col-12 d-flex flex-column mt-3 px-0'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pj' />
                </div>
                {
                  accept && cnpjWithOutMaks?.length === 14 && watch('nome') ? (
                    <Button
                      className='mt-3'
                      target='_blank'
                      rel='noreferrer'
                      href={utmsLink}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          element_action: 'click button',
                          section_name: 'Conta PJ Digital: destrave novas possibilidades pro seu negócio',
                          element_name: 'Abrir Conta PJ gratuita',
                          redirect_url: utmsLink,
                        })
                      }}
                    >Abrir Conta PJ gratuita
                    </Button>
                  ) : (
                    <ButtonDisable className='mt-3' disabled>Abrir Conta PJ gratuita</ButtonDisable>
                  )
                }
                <ButtonReverse
                  target='_blank'
                  rel='noreferrer'
                  href='https://inter.co/empresas/conta-digital/mei/'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      section_name: 'Conta PJ Digital: destrave novas possibilidades pro seu negócio',
                      element_name: 'Conhecer a Conta MEI',
                      redirect_url: 'https://inter.co/empresas/conta-digital/mei/',
                    })
                  }}
                >Conhecer a Conta MEI
                </ButtonReverse>
              </form>

            </Card>
          </div>
        </div>
      </div>
      {width >= WIDTH_MD && (
        <div className='row align-items-center w-100'>
          <div className='col'>
            <ScrollTo
              section='dobra_01'
              sectionName='Conta PJ Digital: destrave novas possibilidades pro seu negócio'
              elementName='Conheça a Conta PJ'
              to='#porque-abrir-conta-pj'
              styles='text-center'
            >
              <ScrollLink className='pb-md-2'>
                <p className='text-white mb-0 fs-14 lh-22 fw-600'>Conheça a Conta PJ</p>
                <div className='d-flex align-items-center justify-content-center'>
                  <OrangeIcon
                    size='MD'
                    color='white'
                    icon='chevron-down'
                  />
                </div>
              </ScrollLink>
            </ScrollTo>
          </div>
        </div>
      )}
    </Section>
  )
}

export default ContaPjDigital
