import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Card } from './style'

import conhecaServicosJson from '../../assets/data/conheca-servicos.json'

type conhecaServicosProps = {
  image: {
    imageXs: string;
    imageMd: string;
    imageLg: string;
    imageXl: string;
  };
  text: string;
  link?: string | null | undefined;
  grid: string;
  cardClass?: string | null;
  backgrounColor: {
    mobile: string;
    desktop: string;
  };
  alt: string;
}

const ConhecaServicosDisponiveis = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-28 fs-md-28 lh-md-32 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-44 text-grayscale--500 fw-500 mb-5'>
              Conheça os serviços disponíveis na <span className='text-orange--extra'>conta jurídica</span>
            </h2>
          </div>
          {
              conhecaServicosJson.map((item: conhecaServicosProps, index: number) => (
                <div className={`${item.grid} `} key={item.text}>
                  <Card
                    className={`${item.cardClass} card-${index}`}
                    bakcgroundImage={item.image}
                    backgrounColor={item.backgrounColor}
                    role='img'
                    aria-label={item.alt}
                  >
                    <div className='text-group'>
                      <h3 className='text-grayscale--500 fw-400 fw-400'>{item.text}</h3>
                      {
                        item.link && (
                          <a
                            href={item.link }
                            target='_blank'
                            rel='noreferrer'
                            className='text-orange--extra fw-600'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_05',
                                element_action: 'click button',
                                section_name: 'Conheça os serviços disponíveis na conta jurídica',
                                element_name: `Saiba mais - ${item.text}`,
                              })
                            }}
                          >Saiba mais
                          </a>
                        )
                      }
                    </div>
                  </Card>
                </div>
              ))
              }
        </div>
      </div>
    </Section>
  )
}

export default ConhecaServicosDisponiveis
