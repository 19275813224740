import { breakpoints } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background: #FDF8EE;
`
export const Card = styled.div`
  background: ${white};
  border-radius: 24px;
  width: 100%;
  height: 183px;
  padding: 27px 27px 27px 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.md}){
    padding: 32px 32px 32px 48px;
    max-width: 330px;
  }

  @media (min-width: ${breakpoints.lg}){
    max-width: 100%;
    padding: 27px 27px 27px 20px;
  }

  h3 {
    font-size: 24px;
    line-height: 28px;

    @media (min-width: ${breakpoints.md}){
      font-size: 20px;
      line-height: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 21px;

    @media (min-width: ${breakpoints.md}){
      font-size: 16px;
      line-height: 19px;
    }

    @media (min-width: ${breakpoints.lg}){
      font-size: 18px;
      line-height: 21px;
    }
  }
`
