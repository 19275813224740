import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'
import ImageWebp from 'src/components/ImageWebp'

export const Section = styled.section``

export const Button = styled.a`
  background: ${orange.extra};
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  &:focus, &:hover {
    outline: none;
    color: ${white} ;
  }

  @media (min-width: ${breakpoints.md}){
    margin-top: 32px;
  }

  @media (min-width: ${breakpoints.lg}){
    margin-top: 40px;
  }

`
export const ImageContainer = styled(ImageWebp)`
  max-width: 100%;
  height: auto;

  @media (min-width: ${breakpoints.md}) {
    max-width: 336px;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 363px;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 502px;
  }

`
