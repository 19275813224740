import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background:  #FDF8EE;

  h2 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
    color: ${grayscale[500]};

    @media (min-width: ${breakpoints.md}) {
      font-size:  28px;
      line-height: 33px;
    }

    @media (min-width: ${breakpoints.lg}) {
      font-size: 40px;
      line-height: 48px;
    }
  }
`
export const Circle = styled.div`
  border-radius: 50%;
  background: ${orange.extra};
  width: 40px;
  height: 40px;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;

`
export const Button = styled.a`
  background: ${orange.extra};
  border: none;
  border-radius: 8px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }

`
