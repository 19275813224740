import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const FaresCard = styled.div`
  p {
    height: 90px;
    @media ${device.desktopLG} {
      height: 70px;
    }
    @media ${device.desktopXL} {
      height: 50px;
    }
  }
  h4 {
    height: 40px;
    @media ${device.desktopLG} {
      height: 30px;
    }
  }
  .bg-white {
    @media ${device.tablet} {
      min-height: 186px;
    }
    @media ${device.desktopLG} {
      min-height: 146px;
    }
    @media ${device.desktopXL} {
      min-height: 140px;
    }
  }
`
