import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

type CardProps = {
  bakcgroundImage: {
    imageXs: string;
    imageMd: string;
    imageLg: string;
    imageXl: string;
  };
  backgrounColor: {
    mobile: string;
    desktop: string;
  };
}

export const Section = styled.section`
  background: #EBEBEB;

  a {
    &:focus, &:hover {
      outline:  none;
      color: ${orange.extra};
    }
  }
`

export const Card = styled.div`
  background-image: url(${(props: CardProps) => props.bakcgroundImage.imageXs});
  background-color: ${(props: CardProps) => props.backgrounColor.mobile};
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20px;
  width: 100%;
  height: 167px;
  margin-bottom: 16px;
  position: relative;

  &.card-1, &.card-3, &.card-4{
    background-position: right;
  }

  &.card-0 .text-group{

      @media (min-width: ${breakpoints.md}){
        left: 449px;
        max-width: 223px;
        transform: translateY(-50%);
        top: 50%;
      }


      @media (min-width: ${breakpoints.lg}){
        max-width: 326px;
        left: 284px;

        h3 {
          margin-bottom: 51px !important;
        }
      }

      @media (min-width: ${breakpoints.xl}){
        left: 344px;


      }

  }

  &.card-1 .text-group{

    @media (min-width: ${breakpoints.md}){
      max-width: 180px;
      top: 50%;
      transform: translateY(-50%);

    }

    @media (min-width: ${breakpoints.lg}){
      max-width: 180px;
      bottom: 26px;
      top: auto ;

    }

}

  &.card-2 .text-group{

      @media (min-width: ${breakpoints.md}){
        max-width: 168px;
        left: 449px;
        top: 50% ;
        transform: translateY(-50%)
      }

      @media (min-width: ${breakpoints.lg}){
        max-width: 168px;
        left: 27px;
        top: 72px;
      }
  }

  &.card-3 .text-group{

    @media (min-width: ${breakpoints.md}){
      max-width: 181px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (min-width: ${breakpoints.lg}){
      top: 72px;
    }

}

&.card-4 .text-group{

    @media (min-width: ${breakpoints.md}){
      max-width: 134px;
    }

    @media (min-width: ${breakpoints.lg}){
       h3{
        margin-bottom: 10px;
       }
    }

    @media (min-width: ${breakpoints.xl}){
      max-width: 252px;

      h3{
        margin-bottom: 10px;
       }
    }
  }

  @media (min-width: ${breakpoints.md}){
    background-image: url(${(props: CardProps) => props.bakcgroundImage.imageMd});
    background-color: ${(props: CardProps) => props.backgrounColor.mobile};
    height: 225px;
  }

  @media (min-width: ${breakpoints.lg}){
    background-image: url(${(props: CardProps) => props.bakcgroundImage.imageLg});
    background-color: ${(props: CardProps) => props.backgrounColor.desktop};
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 20px;
    height: 272px;
  }

  @media (min-width: ${breakpoints.xl}){
    background-image: url(${(props: CardProps) => props.bakcgroundImage.imageXl});
    background-color: ${(props: CardProps) => props.backgrounColor.desktop};
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 20px;
    height: 260px;
  }

  &.card-height {

    @media (min-width: ${breakpoints.lg}){
      height: 346px;
    }
    @media (min-width: ${breakpoints.xl}){
      height: 318px;
    }
  }


  .text-group{
    position: absolute;
    top: 35px;
    left: 32px;
    max-width: 114px;


    @media (min-wdith: ${breakpoints.md}){
      max-width:  181px;
    }


  h3, a {
    font-size: 16px;
    line-height: 19px;

  @media (min-width: ${breakpoints.md}){
    font-size: 18px;
    line-height: 21px;
  }

  @media (min-width: ${breakpoints.lg}){
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: ${breakpoints.xl}){
    font-size: 20px;
    line-height: 24px;
  }

}

  }

`
