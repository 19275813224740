import React from 'react'
import pageContext from '../../pageContext.json'
import Faq from 'src/components/Faq/index'

import {
  PerguntasFrequentes,
} from './style'

const FaqContaDigitalPJ = () => {
  return (
    <PerguntasFrequentes id='perguntas-frequentes' className='bg-gray py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4 px-0'>
            <h3 className='fs-24 lh-28 fs-md-40 lh-md-46 text-grayscale--500 mb-2 mb-lg-1'>Ficou com alguma dúvida?</h3>
            <p className='fs-18 lh-22 fw-400 text-grayscale--500'>
              Veja se podemos te ajudar com estas perguntas frequentes.
            </p>
          </div>
          <Faq id='conta-pj' className='px-0' columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} answerData={pageContext.structuredData.faq} searchBg='#fff' />
          <div className='col-12 col-md-8 mx-auto text-md-center pt-lg-3 pb-lg-4 d-md-none d-lg-block mt-4'>
            <p className='lh-22 text-grayscale--500 mb-0'>
              Se quiser saber mais, acesse nossa
              <a
                className='text-orange--base' href='https://ajuda.bancointer.com.br' target='_blank'
                rel='noreferrer'
              > <u>Central de Ajuda</u>
              </a> para outras perguntas.
            </p>
          </div>
        </div>
      </div>
    </PerguntasFrequentes>
  )
}

export default FaqContaDigitalPJ
