import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

  h2{
    font-size: 32px;
    line-height: 38px;
    color: ${orange.extra};
    font-weight: 500;

    @media (min-width: ${breakpoints.md}){
      font-size: 28px;
      line-height: 33px;
    }

    @media (min-width: ${breakpoints.lg}){
      font-size: 40px;
      line-height: 44px;
    }

    @media (min-width: ${breakpoints.xl}){
      font-size: 48px;
      line-height: 52px;
    }
  }

`
export const Button = styled.a`
  background: ${orange.extra};
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;


  &:hover, &:focus{
    outline: none;
    color: ${white};
  }

  @media (min-width: ${breakpoints.lg}){
    width: 423px;
  }

`
export const ButtonReverse = styled.a`
  background: none;
  height: 48px;
  width: 100%;
  border: 1px solid ${orange.extra};
  border-radius: 8px;
  color: ${orange.extra};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;


  &:hover, &:focus{
    outline: none;
    color: ${orange.extra};
  }

  @media (min-width: ${breakpoints.lg}){
    width: 423px;
  }

`
