import React, { useState, useEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useUtms from 'src/hooks/useUtms'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import { WIDTH_LG } from 'src/utils/breakpoints'
import useWidthResize from 'src/hooks/window/useWidth'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Popup from 'src/components/Popup'

import QrCode from './assets/images/lp-pj-qrcode.jpg'

import pageContext from './pageContext.json'
import { PopupContent, Wrapper } from './style'

import ContaDigitalPJHeader from './sections/conta-digital-pj/_index'
import PorqueAbrirConta from './sections/porque-abrir-conta/_index'
import ParaQuemContaDigitalPj from './sections/para-quem-conta-digital-pj/_index'
import CartaoPjComProgramaDePontos from './sections/cartao-pj-com-programa-de-pontos/_index'
import MaquininhaInterPag from './sections/maquininha-inter-pag/_index'
import ComoAbrirMinhaContaPj from './sections/como-abrir-minha-conta-pj/_index'
import ContaEmpresarial from './sections/conta-empresarial/_index'
import PorqueNaoCobramosTarifas from './sections/porque-nao-cobramos/_index'
import AcompanheAsNovidades from './sections/acompanhe-as-novidades/_index'
import { Dicas } from './sections/fique-por-dentro/_index'
import ConhecaOsServicos from './sections/conheca-servicos/_index'
import FaqContaDigitalPJ from './sections/faq/_index'

const ContaDigitalPJ = () => {
  const domReady = useDomReady()
  const windowWidth = useWidthResize(200)
  const [ sendDatalayerEvent ] = useDataLayer()
  const isDesktop = windowWidth > WIDTH_LG

  const [ isOpen, setIsOpen ] = useState(false)

  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const [ popup, setPopup ] = useSessionStorage('@hide-bancointer-popup-conta-digital-pj', false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopUpChild = (
    <PopupContent className='row popup_content'>
      <div className='popup_content__image col-5'>
        <img
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/popup-pj-negocio/image.webp'
          alt='Duas pessoas olhando um notebook'
          style={{ width: 247, height: 238 }}
        />
      </div>
      <div className='popup_content__texts col-6'>
        <h2 className='popup_content__texts__title'>
          Ebook:
        </h2>
        <p className='popup_content__texts__description'>
          <u className='popup_content__texts__description--highlighted'>Seu negócio no topo</u> <br />
          Soluções Inter Empresas pensadas
          para alavancar o seu negócio.
        </p>
      </div>

      <div className='col-lg-12 mt-lg-5'>
        <a
          className='btn btn--lg bg-orange--extra text-none text-white rounded-2 fs-14 lh-16 fw-700 mt-2 mw-100'
          href=' https://inter.co/empresas/solucoes-inter-empresas/?utm_source=site&utm_medium=popup&utm_campaign=leads_lp_pj_popup&utm_term=conta-digital-pj&utm_content=lp_pj_popup_solucoes_pj'
          target='blank'
          rel='noopener'
          onClick={() => {
            setIsPopupOpen(false)
            sendDatalayerEvent({
              section: 'dobra_01',
              element_action: 'click button',
              section_name: 'Seu negócio no topo Soluções Inter Empresas pensadas para alavancar o seu negócio.',
              element_name: 'Baixar agora!',
              redirect_url: 'https://inter.co/empresas/solucoes-inter-empresas/?utm_source=site&utm_medium=popup&utm_campaign=leads_lp_pj_popup&utm_term=conta-digital-pj&utm_content=lp_pj_popup_solucoes_pj',
            })
          }}
        >
          Baixar agora!
        </a>
      </div>
    </PopupContent>
  )

  const PopupExitPage = domReady && !popup && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup
        child={PopUpChild}
        closeModal={() => setIsPopupOpen(false)}
        setPopup={setPopup}
        pageName='conta-digital-pj'
        theme={{
          closeButton: 'gray',
          backgroundColor: 'white',
        }}
      />
    </Modal>
  )

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Escaneie o QR Code, acesse o app e aumente suas vendas com a Granito'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima.'
        qrCode={QrCode}
      />
    </Modal>
  )

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Wrapper>
      {openModal}
      {PopupExitPage}
      <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
        <ContaDigitalPJHeader />
        <PorqueAbrirConta />
        <ParaQuemContaDigitalPj />
        <CartaoPjComProgramaDePontos />
        <ConhecaOsServicos />
        <MaquininhaInterPag />
        <ComoAbrirMinhaContaPj />
        <ContaEmpresarial />
        <PorqueNaoCobramosTarifas />
        <Dicas />
        <AcompanheAsNovidades />
        <FaqContaDigitalPJ />
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalPJ
