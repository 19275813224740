import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Button, ButtonReverse } from './style'

const CartaoPjComProgramaDePontos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-cartaopj/image.webp'
              altDescription='Cartão PJ Inter Empresas em destaque. Ao fundo vemos 2 moedas que representam os pontos Loop.'
              arrayNumbers={[]}
              arrayNumbersHeight={[]}
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='mb-4'>Cartão PJ com programa de pontos gratuito</h2>
            <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--500 fw-400 mb-4'>
              Além de sem taxas, com o cartão de crédito PJ você usa e a gente te recompensa. É isso mesmo! Com o Inter Loop, você escolhe como e quais benefícios quer utilizar.
            </p>
            <Button
              href='https://inter.co/empresas/cartao-de-credito-empresarial/'
              target='blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  section_name: 'Cartão PJ com programa de pontos gratuito',
                  element_name: 'Saiba mais sobre o Cartão Inter Empresas',
                })
              }}
            >
              Saiba mais sobre o Cartão Inter Empresas
            </Button>
            <ButtonReverse
              href='https://inter.co/empresas/programa-de-pontos-para-empresas/'
              target='blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  section_name: 'Cartão PJ com programa de pontos gratuito',
                  element_name: 'Saiba mais sobre o Inter Loop',
                })
              }}
            >
              Saiba mais sobre o Inter Loop
            </ButtonReverse>
          </div>
        </div>
      </div>

    </Section>
  )
}

export default CartaoPjComProgramaDePontos
