import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Button, Section, ImageContainer } from './style'

import Pix from '@interco/icons/build-v4/orangeds/MD/pix'
import CheckSuccess from '@interco/icons/build-v4/orangeds/MD/check-success'
import DepositByBoleto from '@interco/icons/build-v4/orangeds/XL/deposit-by-boleto'
import Credit from '@interco/icons/build-v4/orangeds/MD/credit'
import MaioresAltas from '@interco/icons/build-v4/bidis/v2/products/investimentos/renda-variavel/maiores-altas'
import ListCheck from '@interco/icons/build-v4/orangeds/MD/list-check'
import Card from '@interco/icons/build-v4/orangeds/MD/card'
import Computer from '@interco/icons/build-v4/orangeds/MD/computer'
import Receipt from '@interco/icons/build-v4/orangeds/MD/receipt'
import Dollar from '@interco/icons/build-v4/orangeds/MD/dollar'

import porqueAbrirConta from '../../assets/data/PorqueAbrirConta.json'

type porqueAbrirConta = {
  text: string;
  icon: string;
}

const PorqueAbrirUmaContaPj = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const width = useWidth(300)
  const getIcons = (icon: string) => {
    switch (icon) {
      case 'pix':
       return <Pix height={24} width={24} color='#FF7A00' />
      case 'check-success':
       return <CheckSuccess height={24} width={24} color='#FF7A00' />
      case 'deposit-by-boleto':
      return <DepositByBoleto height={24} width={24} color='#FF7A00' />
      case 'credit':
        return <Credit height={24} width={24} color='#FF7A00' />
      case 'maiores-altas':
        return <MaioresAltas height={24} width={24} color='#FF7A00' />
      case 'list-check':
        return <ListCheck height={24} width={24} color='#FF7A00' />
      case 'card':
        return <Card height={24} width={24} color='#FF7A00' />
      case 'computer':
        return <Computer height={24} width={24} color='#FF7A00' />
      case 'receipt':
        return <Receipt height={24} width={24} color='#FF7A00' />
      case 'dollar':
         return <Dollar height={24} width={24} color='#FF7A00' />
      default:
       return <CheckSuccess height={24} width={24} color='#FF7A00' />
    }
  }

  return (
    <Section className='py-5' id='porque-abrir-conta-pj'>
      <div className='container'>
        <div className='row d-flex justify-content-between justify-content-md-center justify-content-xl-between'>
          <div className='col-12 col-md-5 col-lg-5 col-xl-6'>
            <ImageContainer
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pj-interpag-cartao/image.webp'
              altDescription='Imagem de um cartão de crédito Inter Empresas. Ao lado em destaque, a home do Super App Inter Empresas'
              arrayNumbers={[ ]}
              arrayNumbersHeight={[ ]}
            />
          </div>
          <div className='col-12 col-md-9 col-lg-7 col-xl-6'>
            <h2 className='fs-24 lh-28 fs-md-28 lh-md-33 fs-lg-40 lh-lg-44 text-grayscale--500 fw-600 mt-3 mb-3'>
              Por que abrir uma conta PJ Inter Empresas?
            </h2>
            <h3 className='fs-16 lh-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400 mb-4'>
              Com o Inter Empresas você aproveita soluções integradas pra diferentes necessidades financeiras do seu negócio.
            </h3>
            <div className='row'>
              {
                  porqueAbrirConta.map((item: porqueAbrirConta) => (
                    <div className='col-12 col-md-6 mb-4' key={item.text}>
                      <div className='d-flex align-items-center'>
                        <div>
                          {getIcons(item.icon)}
                        </div>
                        <p className='fs-16 lh-19 text-grayscale--500 fw-400 mb-0 ml-2'>{item.text}</p>
                      </div>
                    </div>
                  ))
                }
            </div>
            <Button
              href={width < widths.md ? 'https://intergo.app/35060184' : 'https://conta-digital-pj.inter.co/login?/'} target='_blank' rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  section_name: 'Por que abrir uma conta PJ Inter Empresas?',
                  element_name: 'Abrir Conta PJ gratuita',
                })
              }}
            >
              Abrir Conta PJ Gratuita
            </Button>
          </div>
        </div>
      </div>
    </Section>

  )
}

export default PorqueAbrirUmaContaPj
