import React from 'react'

import { Section, Card } from './style'

import UserAccount from '@interco/icons/build-v4/orangeds/MD/user-account'
import Handbag from '@interco/icons/build-v4/orangeds/MD/handbag'
import Users from '@interco/icons/build-v4/orangeds/MD/users'
import Building from '@interco/icons/build-v4/orangeds/MD/building'

import paraQuemContaDigital from '../../assets/data/ParaQuemContaDigitalPj.json'

type paraQuemContaDigitalProps = {
  title?: string | null;
  text: string;
  icon: string;
}

const ParaQuemContaDigitalPj = () => {
const getIcons = (icon: string) => {
  switch (icon) {
    case 'user-account':
      return <UserAccount height={40} width={40} color='#FF7A00' />
    case 'handbag':
      return <Handbag height={40} width={40} color='#FF7A00' />
    case 'users':
      return <Users height={40} width={40} color='#FF7A00' />
    case 'building':
      return <Building height={40} width={40} color='#FF7A00' />
     default:
        return <UserAccount height={40} width={40} color='#FF7A00' />
  }
}

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-32 lh-38 fs-md-40 lh-md-40 fs-xl-48 lh-xl-52 text-orange--extra fw-600 text-center mb-4'>
              Para quem é a conta digital PJ Inter Empresas?
            </h2>
          </div>
          <div className='col-12'>
            <div className='row d-flex justify-content-center'>
              {
                  paraQuemContaDigital.map((item: paraQuemContaDigitalProps) => (
                    <div className='col-12 col-md-6 col-lg-5 col-xl-4' key={item.title}>
                      <Card>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div>
                            {getIcons(item.icon)}
                          </div>
                          <div className='ml-4'>
                            <h3 className='text-grayscale--500 fw-600 mb-2'>{item.title}</h3>
                            <p className='text-grayscale--500 fw-400 mb-0' dangerouslySetInnerHTML={{ __html: item.text }} />
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))
                }
            </div>
          </div>
        </div>
      </div>
    </Section>

  )
}

export default ParaQuemContaDigitalPj
